import React, { Fragment } from 'react'
import DefaultLayout from '../components/DefaultLayout/default-layout'
import sampleImage from '../assets/images/sample_image.jpg'

import './print-sample.css'

const PrintSamplePage = () => (
  <DefaultLayout titleSuffix="PrintSample">
    <Fragment>
      <section className="sample-page-content">
        <h2>Sample Heading</h2>
        <p>
          This is a sample paragraph. Need to type in random stuff to make it
          bigger. May be a few more characters. How about a few more random
          words? Typing in some more random sentences. Yes, this is a sampple
          and hence it is absolutely random stuff here!
        </p>
        <p>
          Yet another sample paragraph. Need to type in random stuff to make it
          bigger. May be a few more characters. How about a few more random
          words? Copy paste stuff!
        </p>
        <h3>This is a sample table </h3>
        <table className="sample-table">
          <thead>
            <tr>
              {[1, 2, 3, 4].map((data) => (
                <th className="sample-table-header">Heading {data}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3].map((row) => (
              <tr key={row}>
                {[1, 2, 3, 4].map((col) => (
                  <td className="sample-cell" key={row + '_' + col}>
                    Row {row} Column {col}{' '}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        <h3>Some sample image</h3>
        <img id="sampleImage" src={sampleImage} alt="Some sample image" />

        <p>
          Yet another sample paragraph. This is the last one of its kind. Full
          of random stuff. It should not make any sense!
        </p>
      </section>
    </Fragment>
  </DefaultLayout>
)
export default PrintSamplePage
